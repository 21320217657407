.quote-wrapper {
  color: #05174b;
  background: #e9eefb;
  border-radius: 2px;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 80px;
  display: flex;
}

.quote-wrapper .quote-icon {
  width: 49px;
}

.quote-wrapper .quote-text p {
  text-align: center;
  margin: 0;
  font-size: 24px;
  line-height: 32px;
}

.quote-wrapper .quote-footer {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.quote-wrapper .author-wrapper {
  gap: 16px;
  display: flex;
}

.quote-wrapper .author-wrapper .author-info {
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.quote-wrapper .author-wrapper .author-info .author-name {
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
}

.quote-wrapper .author-wrapper .author-info .author-profession {
  color: #7182b6;
  font-size: 16px;
  line-height: 24px;
}

.mobile .slider-track .active {
  padding: 32px 16px 48px;
}

.mobile .quote-wrapper {
  padding: 0;
}
/*# sourceMappingURL=index.3f10c249.css.map */
