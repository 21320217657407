@import '/packages/site/src/public/styles/colors.scss';

.quote-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 80px;
  gap: 40px;
  background: $ui_02;
  border-radius: 2px;
  width: 100%;
  color: $text_01;

  .quote-icon {
    width: 49px;
  }

  .quote-text p {
    margin: 0;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }

  .quote-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .author-wrapper {
    display: flex;
    gap: 16px;

    .author-info {
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      .author-name {
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
      }

      .author-profession {
        font-size: 16px;
        line-height: 24px;
        color: $text_03;
      }
    }
  }
}

.mobile {
  .slider-track .active {
    padding: 32px 16px 48px;
  }

  .quote-wrapper {
    padding: 0;
  }

}
